import React from 'react'

import { Invoice, InvoiceStatus } from '../../types'
import Amount from '../ui/currency/amount'
import StatusBadge from './statusBadge'
import Button, { ButtonVariant } from '../ui/button'
import { Link } from 'react-router-dom'

type Props = { invoice: Invoice, handlePaid: (id?: string, code?: string) => void }
const InvoiceCard: React.FC<Props> = ({ invoice, handlePaid }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  return (
    <div className={`card mb-3 shadow-sm ${isExpanded ? 'border-secondary' : ''}`} onClick={() => setIsExpanded(!isExpanded)}>
      <div className='card-body'>
        <div className='d-flex align-items-center justify-content-between'>
          <small className='text-muted'>{new Date(invoice.issueDate).toLocaleDateString()} | {invoice.code}</small>
          <div className=''>{<StatusBadge status={(invoice.status as InvoiceStatus)} />}</div>
        </div>
        <div className=' d-flex align-items-center justify-content-between'>
          <h5 className=''>{invoice.client?.tradingName}</h5>
          <div>
            <b className='text-primary'>
              <Amount value={invoice.total as number} currencySymbol={invoice?.businessDetails?.currency?.symbol} />
            </b>
          </div>
        </div>
        {isExpanded && (
          <div className='mt-4 d-flex align-items-center justify-content-end gap-4'>
            <Link to={`/invoices/${invoice._id}`}><Button variant={ButtonVariant.SECONDARY}>View</Button></Link>
            {invoice.status !== InvoiceStatus.PAID && <Button variant={ButtonVariant.LIGHT} onClick={() => handlePaid(invoice._id, invoice.code)}>Paid</Button>}
          </div>
        )}
      </div>
    </div>
  )
}

export default InvoiceCard
