import React from 'react'
import useSWR from 'swr'

import { getInvoices } from '../../api'
import StatusSelector from './statusSelector'
import ClientSelector from '../clients/selector'
import SimpleList from './simpleList'
import { InvoiceStatus } from '../../types'
import Spinner from '../ui/spinner'

/**
   * Build filters url from the filters state
   */
const getFilters = (filters: {[x: string]: any}) => {
  const filterKeys = Object.keys(filters)
  if(filterKeys.length === 0) {
    return ''
  }
  const filterString = filterKeys.map((key: string) => {
    return `${key}=${filters[key]}`
  }).join('&')
  return `?${filterString}`
}

/**
 * Shows a list of invoices.
 * Looks like a table with the following columns:
 * Issue Date,
 * Code,
 * Client,
 * Amount,
 * Status: Open, Paid, Overdue
 * Activity: Draft, Email Sent, Client Viewed
 * @returns
 */
const InvoiceList = () => {
  const [filters, setFilters]  = React.useState<{[x: string]: any}>({ status: [InvoiceStatus.OPEN, InvoiceStatus.OVERDUE] })
  const { data, isLoading } = useSWR(`/api/invoices${getFilters(filters)}`, getInvoices)

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target
    if(!value || value === '') {
      const newFilters = {...filters}
      delete newFilters[name]
      return setFilters(newFilters)
    } else {
      setFilters({...filters, [name]: value})
    }
  }

  return (
    <>
      <div className='row align-items-center'>
        <div className='col'><InvoiceFilters onChange={handleFilterChange} defaultValue={filters.status} /></div>
      </div>
      {(isLoading) && <Spinner />}
      {!isLoading && data?.length === 0 && (
        <div className='alert'>
          No invoices found for those filters
        </div>
      )}
      {!isLoading && data?.length > 0 && (
        <div>
          <SimpleList invoices={data} />
        </div>
      )}
    </>
  )
}

const InvoiceFilters = ({ defaultValue, onChange }: { defaultValue: string | string[],onChange: (event: any) => void }) => {
  return (
    <div className='my-4'>
      <div className='py-3'>
        <div className='row row-gap-3'>
          <div className='col-12 col-md-6 col-lg-4'>
            <StatusSelector name='status' id='status' onChange={onChange} showAll size='sm' defaultValue={defaultValue}/>
          </div>
          <div className='col-12 col-md-6 col-lg-4'>
            <ClientSelector size='sm' onChange={onChange} hideNewLink showAll />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceList
