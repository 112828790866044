import React from 'react'
import Spinner from '../spinner'

import './styles.scss'

type Props = {
  children: React.ReactNode
  variant?: ButtonVariant
  size?: ButtonSize
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  [x: string]: any
}

export enum ButtonVariant {
  PRIMARY = 'btn-primary',
  SECONDARY = 'btn-light',
  LIGHT = 'btn-outline-primary',
}

export enum ButtonSize {
  DEFAULT = '',
  LARGE = 'btn-lg',
}

const Button: React.FC<Props> = ({ children, disabled, loading, loadingText='Loading...', size = ButtonSize.DEFAULT, variant = ButtonVariant.PRIMARY, ...rest}) => {
  return (
    <button className={`button btn ${variant} ${size}`} disabled={loading || disabled} {...rest}>
      {loading
        ? <><Spinner /> <span className='sr-only'>{loadingText}</span></>
        : children
      }
    </button>
  )
}

export default Button
