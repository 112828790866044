import React from 'react'
import { Link } from 'react-router-dom'

import { Invoice, InvoiceStatus } from '../../types'
import List from '../ui/list'
import ActivityBadge from './activityBadge'
import Amount from '../ui/currency/amount'
import Button, { ButtonVariant } from '../ui/button'
import useSWRMutation from 'swr/mutation'
import { saveOrUpdateInvoice } from '../../api'
import Spinner from '../ui/spinner'
import { useSWRConfig } from 'swr'
import useScreenSize from '../../context/screenSize'
import InvoiceCard from './Card'
import StatusBadge from './statusBadge'

/**
 * Shows a list of invoices.
 * Looks like a table with the following columns:
 * Issue Date,
 * Code,
 * Client,
 * Amount,
 * Status: Open, Paid, Overdue
 * Activity: Draft, Email Sent, Client Viewed
 * @returns
 */
type Props = { invoices: Array<Invoice> }
const InvoiceList: React.FC<Props> = ({ invoices }) => {
  const { isMobile } = useScreenSize()
  const { mutate } = useSWRConfig()
  const { trigger: updateInvoice, isMutating: isUpdatingInvoice } = useSWRMutation('/api/invoices', saveOrUpdateInvoice, { onSuccess: () => {
    mutate('/api/invoices')
  }})

  const handlePaid = (id?: string, code?: string) => {
    if(id && id?.toString().trim() !== '') {
      if (window.confirm(`Are you sure you want to set as PAID invoice #${code}`) === true) {
        updateInvoice({ body: { _id: id, status: InvoiceStatus.PAID } })
      }
    }
  }

  const headers = [
    { label: <h6>Issue Date</h6> },
    { label: <h6># Code</h6> },
    { label: <h6>Client</h6> },
    { label: <h6>Total</h6> },
    { label: <h6 className='text-center'>Status</h6> },
    { label: <h6 className='text-center fs-7 d-none d-sm-block'>Activity</h6> },
    { label: <h6 className='text-center fs-7'>Actions</h6> }
  ]

  const rows: Array<any[]> = []

  if(invoices) {
    invoices.forEach((invoice: Invoice) => {
      rows.push([
        <small className='text-muted'>{new Date(invoice.issueDate).toLocaleDateString()}</small>,
        <small className='text-muted'><Link to={`/invoices/${invoice._id}`}>{invoice.code}</Link></small>,
        <small className='text-muted text-truncate'><Link to={`/clients/${invoice.client?._id}`}>{invoice.client?.tradingName}</Link></small>,
        <b className='text-primary'>
          <Amount value={invoice.total as number} currencySymbol={invoice?.businessDetails?.currency?.symbol} />
        </b>,
        <div className='text-center'>{<StatusBadge status={(invoice.status as InvoiceStatus)} />}</div>,
        <div className='d-none d-sm-block text-center'>
          <ActivityBadge activity={invoice.lastActivity} />
        </div>,
        <div className='d-none d-sm-block text-center'>
          {invoice.status !== InvoiceStatus.PAID && <Button variant={ButtonVariant.LIGHT} onClick={() => handlePaid(invoice._id, invoice.code)}>Paid</Button>}
        </div>,
      ])
    })
  }

  if(isUpdatingInvoice) return <Spinner />

  if(isMobile) {
    return (<>
      {invoices.map((invoice: Invoice) => (
        <InvoiceCard key={invoice._id} invoice={invoice} handlePaid={handlePaid} />
      ))}
    </>)
  }
  return <List headers={headers} rows={rows} />
}

export default InvoiceList
