import React from 'react'

import './styles.scss'

type Props = {
  headers: Array<{ label: string | React.ReactNode }>
  rows: Array<React.ReactNode[]>
  hideHeaders?: boolean
}

const List: React.FC<Props> = ({ headers, rows, hideHeaders }) => {
  return (
    <div className='table-responsive-sm'>
      <table className='custom-table table table-striped table-borderless'>
        {!hideHeaders && (<thead><tr>
          {headers.map(({ label }, index) => (
            <th key={`header-row-${index}`}>{label}</th>
          ))}
        </tr></thead>)}
        <tbody className='custom-body'>
          {rows.length === 0 && <p className='p-1'>Empty.</p>}
            {rows && rows.map((row, index) => (
              <tr key={`label-tr-${index}`}>
                {row.map((cell, index) => (
                  <td key={`label-td-${index} `}>{cell}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default List
