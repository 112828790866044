import React from 'react'

import { InvoiceStatus } from '../../types'

type Props = { status: InvoiceStatus }
const StatusBadge: React.FC<Props> = ({ status }) => {
  switch(status) {
    case InvoiceStatus.PAID:
      return <div className='badge bg-success'>Paid</div>
    case InvoiceStatus.OVERDUE:
      return <div className='badge bg-danger'>Overdue</div>
    default:
      return <div className='badge bg-light text-dark'>Open</div>
  }
}

export default StatusBadge
